import React from 'react';
import ReactDOM from 'react-dom';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN';
import {getConfig} from './api'
import {MyContext} from './context'
const {Provider,Consumer}=MyContext


/*function getComponents(match) {
  return match.map(v => v.route.component).reduce(async (result, component) => {
    return [...(await result), component];
  }, []);
}
const asyncMatchRoutes = async (routes, pathname) => {
  const match = matchRoutes(routes, pathname);
  const components = await getComponents(match);
console.log('components',components,match)
  return { components, match, };
};
asyncMatchRoutes(routes,location.pathname)*/

getConfig().then(result=>{
  const config=result.data
  import (`./${config.private?'routes-private':'routes'}`).then(({default:routes})=>{
    ReactDOM.render(
      <Provider value={{config}}>
        <ConfigProvider locale={zhCN}>
          <BrowserRouter>
            {renderRoutes(routes)}
          </BrowserRouter>
        </ConfigProvider>
      </Provider>

      , document.getElementById('root'));
  })


})




if (module.hot) {
  module.hot.accept();
}
